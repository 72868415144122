import Cookies from "js-cookie";

interface Experiment {
  experiment: string | number;
  variant: number;
}

export default class Variator {
  currentExperiments: Experiment[];

  constructor() {
    this.currentExperiments = [];
  }

  trackExperiments() {
    this.currentExperiments.forEach(({ experiment, variant }) => {
      window.ga("set", "exp", `${experiment}.${variant}`);
    });
  }

  getVariant(experimentId: Experiment["experiment"], numberOfVariants: number) {
    const storageId = `fellow-experiment-${experimentId}`;
    let value = parseInt(Cookies.get(storageId) ?? "NaN");

    if (isNaN(value)) {
      value = Math.floor(Math.random() * numberOfVariants);
      Cookies.set(storageId, value.toString(), { expires: 30 });
    }

    this.currentExperiments.push({
      experiment: experimentId,
      variant: value,
    });

    return value;
  }
}
