import Cookies from "js-cookie";

const hideTopBannerCookie = "fellow-hide-top-banner";

function isTopBannerOnCooldown() {
  return Boolean(Cookies.get(hideTopBannerCookie));
}

function closedBanner() {
  Cookies.set(hideTopBannerCookie, "true", { expires: 14 });
}

export default {
  isTopBannerOnCooldown,
  closedBanner,
};
