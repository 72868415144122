/**
 * Lightweight modal; apparently this still needs to be made-to-measure in 2019.
 */

import "element-closest";

export default function init() {
  window.fellowModalCurrent = null;

  document.body.addEventListener("click", e => {
    const target = e.target;
    if (!(target instanceof HTMLElement)) return;

    const trigger = target.closest("[fellow-modal-trigger]");
    if (trigger) {
      const triggerSelector = trigger.getAttribute("fellow-modal-trigger");
      if (triggerSelector) {
        const modal = document.querySelector<HTMLElement>(triggerSelector);
        if (modal) {
          openModal(modal);
          e.preventDefault();
        }
      }
    } else if (target.closest("[fellow-modal-close]")) {
      const modal = target.closest("[fellow-modal-parent]");
      if (modal) {
        closeModal(modal);
        e.preventDefault();
      }
    } else if (target.matches("[fellow-modal-parent]")) {
      const modal = target;
      if (modal) {
        closeModal(modal);
        e.preventDefault();
      }
    }
  });
}

export function openModal(parent: HTMLElement) {
  parent.style.display = "flex";
  parent.classList.add("open");
  document.body.classList.add("fellow-modal-is-open");
  parent.style.display = "";
  window.fellowModalCurrent = parent;
}

export function closeModal(parent: Element) {
  parent.classList.remove("open");
  document.body.classList.remove("fellow-modal-is-open");
  window.fellowModalCurrent = null;
}
