export default function capterraTracking() {
  const capterraVKey = "8a1d10b8c3938f1d6ff1d09003740d93";
  const capterraVId = "2136899";
  const capterraPrefix = "https:" === document.location.protocol ? "https://ct.capterra.com" : "http://ct.capterra.com";
  const ct = document.createElement("script");
  ct.type = "text/javascript";
  ct.async = true;
  ct.src = capterraPrefix + "/capterra_tracker.js?vid=" + capterraVId + "&vkey=" + capterraVKey;
  const s = document.getElementsByTagName("script")[0];
  s.parentNode?.insertBefore(ct, s);
}
