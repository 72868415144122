import { GrowthBook } from "@growthbook/growthbook";
import { generateUUID } from "~/blocks/utils";
import Cookies from "js-cookie";
import bowser from "bowser";
import type { FellowAccount } from "~/types/main";

const browser = bowser.getParser(window.navigator.userAgent);
const analyticsAttributes = {
  operating_system: browser.getOSName(),
  os_version: browser.getOSVersion(),
  browser: browser.getBrowserName(),
  browser_version: browser.getBrowserVersion(),
  manufacturer: browser.getPlatform().vendor,
  model: browser.getPlatform().model,
  is_mobile: browser.is("mobile"),
  is_tablet: browser.is("tablet"),
  is_pc: browser.is("desktop"),
  is_native: false,
};

let fellowAccount: FellowAccount | null = null;
try {
  fellowAccount = Object.values<FellowAccount>(JSON.parse(Cookies.get("fellow_accounts") || "{}"))[0];
} catch (e) {
  fellowAccount = null;
}

function ensureAnonymousId(id: string) {
  window.analytics?.setAnonymousId?.(id);
  return id;
}

function generateAndSaveNewAnonymousId() {
  const newId = generateUUID();
  Cookies.set("ajs_anonymous_id", newId, { expires: 365, sameSite: "lax" }); // parameters taken from analytics.js
  return ensureAnonymousId(newId);
}

function getAnonymousId() {
  try {
    if (window.analytics.user) {
      return window.analytics.user().anonymousId();
    }
    const aIdFromLocalStorage = window.localStorage.getItem("ajs_anonymous_id");
    const aIdFromCookie = Cookies.get("ajs_anonymous_id");
    if (aIdFromCookie && aIdFromCookie === aIdFromLocalStorage) {
      return aIdFromCookie;
    }
    if (aIdFromCookie) {
      return ensureAnonymousId(aIdFromCookie);
    } else if (aIdFromLocalStorage) {
      return ensureAnonymousId(aIdFromLocalStorage);
    } else {
      return generateAndSaveNewAnonymousId();
    }
  } catch (error) {
    return generateAndSaveNewAnonymousId();
  }
}

const user = {
  id: Cookies.get("ajs_user_id") || generateUUID(),
  anonId: getAnonymousId(),
};

export const growthbook = new GrowthBook({
  ...window.growthbook_data,
  enableDevMode: window.location.hostname !== "fellow.app",
  attributes: {
    ...(window.growthbook_data.attributes || {}),
    is_authenticated: false,
    account_id: fellowAccount?.id ?? null,
    anonymous_id: user.anonId,
    page_view_id: generateUUID(),
  },
  user,
  trackingCallback: (experiment, result) => {
    const analyticsParams: Record<string, unknown> = {
      experiment_id: experiment.key,
      variation_id: String(result.variationId),
      feature_id: String(result.featureId),
      engine: "growthbook",
      granularity: "instance",
      ...window._growthbook?.getAttributes(),
      ...analyticsAttributes,
    };
    window.analytics.track("Experiment Viewed", analyticsParams);
  },
});

growthbook.loadFeatures({ autoRefresh: true });
