const actionItemCheckboxClass = "fgb-action-item__checkbox";
const dataStorageKey = "fgb-action-item-data";

type HydrationData = { [key: string]: boolean };

function getCurrentData(): HydrationData {
  const data = window.localStorage.getItem(dataStorageKey);
  if (!data) return {};

  try {
    return JSON.parse(data) || {};
  } catch (err) {
    console.error("Invalid fgb-action-item data store encountered: ", data);
    return {};
  }
}

function handleChangeEvent({ srcElement }: Event) {
  if (!(srcElement instanceof HTMLInputElement)) return;

  const { value, checked } = srcElement;
  const data = getCurrentData();

  data[value] = checked;
  window.localStorage.setItem(dataStorageKey, JSON.stringify(data));
}

function hydrate(hydrationData: HydrationData, initial = false) {
  const checkboxes = document.getElementsByClassName(actionItemCheckboxClass) as HTMLCollectionOf<HTMLInputElement>;

  for (const checkbox of checkboxes) {
    if (hydrationData[checkbox.value] !== undefined) {
      checkbox.checked = hydrationData[checkbox.value];
    }

    if (initial) {
      checkbox.addEventListener("change", handleChangeEvent);
    }
  }
}

export default function init() {
  hydrate(getCurrentData(), true);

  window.addEventListener("storage", ev => {
    if (ev.key === dataStorageKey) {
      if (ev.newValue) hydrate(JSON.parse(ev.newValue));
    }
  });
}
